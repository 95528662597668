var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "card" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v("資料の基本設定を選択してください"),
            ]),
            _c("div", { staticClass: "divider bold" }),
            _c("div", { staticClass: "input-list padding" }, [
              _c("div", { staticClass: "input-container" }, [
                _vm._m(0),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name",
                      },
                    ],
                    staticClass: "input select",
                    staticStyle: { flex: "1" },
                    attrs: {
                      type: "text",
                      placeholder: "名称を入力してください。（30文字以内）",
                      maxlength: 30,
                    },
                    domProps: { value: _vm.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.name = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "input-container" }, [
                _vm._m(1),
                _c("div", { staticClass: "input select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentLayout,
                          expression: "currentLayout",
                        },
                      ],
                      attrs: { required: "", name: "layout", id: "layout" },
                      on: {
                        input: _vm.onSelectLayout,
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currentLayout = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v("レイアウトを選択してください")]
                      ),
                      _c("option", { attrs: { value: "dictionary-layout" } }, [
                        _vm._v("辞書型レイアウト"),
                      ]),
                      _c("option", { attrs: { value: "resume-layout" } }, [
                        _vm._v("履歴書型レイアウト"),
                      ]),
                      _c("option", { attrs: { value: "enlargement-layout" } }, [
                        _vm._v("画像拡大型レイアウト"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.currentLayout
            ? _c("div", { staticClass: "card wide" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v("項目を追加してください"),
                ]),
                _c("div", { staticClass: "divider bold" }),
                _c("div", { staticClass: "add-btn-list" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn add",
                      on: { click: _vm.onAddTextClick },
                    },
                    [_vm._v("テキスト")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn add",
                      on: { click: _vm.onAddSquareClick },
                    },
                    [_vm._v("正方形画像")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn add",
                      on: { click: _vm.onAddLandscapeClick },
                    },
                    [_vm._v("長方形(横)画像")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn add",
                      on: { click: _vm.onAddPortraitClick },
                    },
                    [_vm._v("長方形(縦)画像")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "card" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v("項目名を入力してください"),
            ]),
            _c("div", { staticClass: "divider bold" }),
            _c(
              "div",
              { staticClass: "input-list gap-none" },
              [
                !_vm.currentLayout
                  ? _c("p", { staticClass: "warning" }, [
                      _vm._v("レイアウトを選択してください"),
                    ])
                  : _vm._e(),
                _vm._l(_vm.currentBasicItems, function (item) {
                  return _c("material-template-input-item", {
                    key: item.id,
                    attrs: { item: item, isBasic: true },
                  })
                }),
                _c("sortable-list", {
                  attrs: {
                    items: _vm.currentCustomItems,
                    keyAttr: "id",
                    onReorder: _vm.onReorder,
                    handleClassName: ".drag-icon",
                  },
                  on: {
                    "update:items": function ($event) {
                      _vm.currentCustomItems = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c("material-template-input-item", {
                            key: item.id,
                            attrs: {
                              item: item,
                              checkedItems: _vm.checkedItems,
                              updateItem: _vm.onUpdateItem,
                              updateCheckedItems: _vm.onUpdateCheckItem,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm.currentLayout
          ? _c("div", { staticClass: "card narrow" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("項目を追加してください"),
              ]),
              _c("div", { staticClass: "divider bold" }),
              _c("div", { staticClass: "add-btn-list padding" }, [
                _c(
                  "button",
                  { staticClass: "btn add", on: { click: _vm.onAddTextClick } },
                  [_vm._v("テキスト")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn add",
                    on: { click: _vm.onAddSquareClick },
                  },
                  [_vm._v("正方形画像")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn add",
                    on: { click: _vm.onAddLandscapeClick },
                  },
                  [_vm._v("長方形(横)画像")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn add",
                    on: { click: _vm.onAddPortraitClick },
                  },
                  [_vm._v("長方形(縦)画像")]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "action-btn-list" }, [
          _c(
            "button",
            {
              staticClass: "btn save",
              class: { active: _vm.isActiveSaveButton },
              on: { click: _vm.onSaveButtonClick },
            },
            [_vm._v("保存する")]
          ),
          _c(
            "button",
            {
              staticClass: "btn delete",
              class: { active: _vm.isActiveDeleteButton },
              on: { click: _vm.onDeleteButtonClick },
            },
            [_vm._v(" 削除する" + _vm._s(_vm.deleteCount) + " ")]
          ),
          _c(
            "a",
            { staticClass: "cancel pointer", on: { click: _vm.onCancelClick } },
            [_vm._v("キャンセル")]
          ),
        ]),
      ]),
      _vm.isProgress ? _c("masked-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", { attrs: { for: "attribute" } }, [
      _vm._v("資料テンプレート名称 "),
      _c("span", { staticClass: "require" }, [_vm._v("＊")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", { attrs: { for: "layout" } }, [
      _vm._v("資料の表示レイアウトを選択する "),
      _c("span", { staticClass: "require" }, [_vm._v("＊")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }