
import Vue from "vue";
import SortableList from "@/components/molecules/SortableList.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { LayoutEnum, MaterialInputTemplate, MaterialItem, MaterialItemEnum } from "@/lib/models";
import MaterialTemplateInputItem from "@/components/molecules/lists/MaterialTemplateInputItem.vue";
import {
  Dialog,
  initLandscapeItem,
  initMultilineItem,
  initPortrateItem,
  initSinglelineItem,
  initSquareItem,
  isBilling,
} from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import DialogVue from "@/components/ui/Dialog.vue";
import { NavigationGuardNext } from "vue-router";
import _ from "lodash";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

interface Data {
  name: string;
  currentLayout: LayoutEnum | null;
  currentBasicItems: MaterialItem[];
  currentCustomItems: MaterialItem[];
  checkedItems: MaterialItem[];
  isProgress: boolean;
  originalData: TOriginalData;
  unbindShortcutKeys: (() => void) | null;
}

interface Methods {
  initialize: () => void;
  setBasicItems: (layout: string) => void;
  addCustomItem: (type: MaterialItemEnum) => void;
  createTemplate: () => void;
  updateTemplate: () => void;
  updateCustomItem: (item: MaterialItem) => void;
  updateCheckedItems: (item: MaterialItem) => void;
  deleteTemplate: () => void;
  onReorder: (items: MaterialItem[]) => void;
  onSelectLayout: (event: Event) => void;
  onAddTextClick: () => void;
  onAddSquareClick: () => void;
  onAddPortraitClick: () => void;
  onAddLandscapeClick: () => void;
  onSaveButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onUpdateItem: (item: MaterialItem) => void;
  onUpdateCheckItem: (item: MaterialItem) => void;
  onCancelClick: () => void;
  showErrorDialog: (message: string) => void;
  showLeaveConfirmDialog: (next: NavigationGuardNext<Vue>) => void;
  beforeUnload: (event: BeforeUnloadEvent) => void;
  initializeOriginalData: () => void;
}

interface Computed {
  deleteCount: string;
  isCreate: boolean;
  isActiveSaveButton: boolean;
  isActiveDeleteButton: boolean;
  isFormChanged: boolean;
}

interface Props {
  novelId: string;
  templateId: string;
}

type TOriginalData = {
  name: string;
  currentLayout: LayoutEnum | null;
  currentBasicItems: MaterialItem[];
  currentCustomItems: MaterialItem[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },

  components: { SortableList, MaskedLoading, MaterialTemplateInputItem },

  data() {
    return {
      name: "",
      currentLayout: null,
      currentBasicItems: [],
      currentCustomItems: [],
      checkedItems: [],
      isProgress: false,
      originalData: {
        name: "",
        currentLayout: null,
        currentBasicItems: [],
        currentCustomItems: [],
      },
      unbindShortcutKeys: null,
    };
  },

  props: {
    // URL path property
    novelId: String,
    // URL path property
    templateId: String,
  },

  created() {
    const { initialize } = this;
    initialize();
    window.addEventListener("beforeunload", this.beforeUnload);

    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys([
      { trigger: TriggerKey.Meta, keys: ["s"], callback: this.onSaveButtonClick },
      { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.onSaveButtonClick },
    ]);
  },

  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },

  computed: {
    deleteCount() {
      const { checkedItems } = this;
      if (checkedItems.length > 0) {
        return ` (${checkedItems.length})`;
      }

      return "";
    },
    isCreate() {
      const { $store, templateId } = this;
      const { getters } = $store;
      const template = getters["materialModule/template"](templateId);

      if (template) {
        return false;
      }

      return true;
    },
    isActiveSaveButton() {
      const { name, currentLayout } = this;

      if (!name || !currentLayout) {
        return false;
      }

      if (name.length < 1) {
        return false;
      }

      return true;
    },
    isActiveDeleteButton() {
      const { checkedItems, isCreate } = this;

      if (checkedItems.length > 0) {
        return true;
      }

      return !isCreate;
    },
    isFormChanged() {
      const currentData: TOriginalData = {
        name: this.name,
        currentLayout: this.currentLayout,
        currentBasicItems: this.currentBasicItems,
        currentCustomItems: this.currentCustomItems,
      };

      return !_.isEqual(currentData, this.originalData);
    },
  },

  methods: {
    // init

    async initialize() {
      const { $store, $router, templateId } = this;
      const { getters } = $store;

      const template = getters["materialModule/template"](templateId) as MaterialInputTemplate;
      const pushAnnounce = !(await isBilling($store));
      if (!template && pushAnnounce) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "createMaterialInputTemplate" } });
        return;
      }

      if (!template) {
        // フォームの値の変更を検知する為に初期値を保存
        this.initializeOriginalData();
        return;
      }

      const { setBasicItems } = this;
      const { name, layout } = template;
      this.name = name;
      this.currentLayout = layout;
      setBasicItems(layout);
      this.currentCustomItems = template.items;
      // フォームの値の変更を検知する為に初期値を保存
      this.initializeOriginalData();
    },

    // update value (or execute action)

    setBasicItems(layout) {
      const items = [initSinglelineItem("名称"), initSinglelineItem("ふりがな", false)];
      switch (layout as LayoutEnum) {
        case LayoutEnum.RESUME:
          items.push(initSquareItem());
          break;
        case LayoutEnum.ENLARGEMENT:
          items.push(initLandscapeItem());
          break;
        case LayoutEnum.DICTIONARY:
        default:
          break;
      }

      this.currentBasicItems = items;
    },
    addCustomItem(type) {
      const { currentCustomItems, showErrorDialog } = this;

      if (currentCustomItems.length > 29) {
        showErrorDialog("追加できる項目は30個までです。");
        return;
      }

      let item;
      switch (type) {
        case MaterialItemEnum.IMAGE_SQUARE:
          item = initSquareItem();
          break;
        case MaterialItemEnum.IMAGE_PORTRATE:
          item = initPortrateItem();
          break;
        case MaterialItemEnum.IMAGE_LANDSCAPE:
          item = initLandscapeItem();
          break;
        case MaterialItemEnum.TEXT_MULTILINE:
        default:
          item = initMultilineItem();
          break;
      }

      currentCustomItems.push(item);
    },
    async createTemplate() {
      const { $store, templateId: id, currentLayout: layout, name, currentCustomItems, showErrorDialog } = this;

      if (!layout) {
        showErrorDialog(`資料レイアウトが設定されていません。`);
        return;
      }

      const { dispatch } = $store;
      const items = currentCustomItems.map((item) => ({ ...item, id: undefined }));
      const template: MaterialInputTemplate = {
        id,
        name,
        layout,
        items,
      };

      await dispatch("materialModule/createMaterialInputTemplate", template);
      const { $router, novelId } = this;
      $router.push({
        name: "templates",
        params: {
          novelId,
          templateId: id,
        },
      });
      this.$notify({
        title: "作成しました",
        type: "success",
      });
      this.isProgress = false;
    },
    async updateTemplate() {
      const { $store, templateId: id, currentLayout: layout, name, currentCustomItems, showErrorDialog } = this;

      if (!layout) {
        showErrorDialog(`資料レイアウトが設定されていません。`);
        return;
      }

      const { dispatch } = $store;
      const items = currentCustomItems.map((item) => ({ ...item, id: undefined }));
      const template: MaterialInputTemplate = {
        id,
        name,
        layout,
        items,
      };

      await dispatch("materialModule/updateMaterialInputTemplate", template);
      const { $router, novelId } = this;
      $router.push({
        name: "templates",
        params: {
          novelId,
          templateId: id,
        },
      });
      this.$notify({
        title: "保存しました",
        type: "success",
      });
      this.isProgress = false;
    },
    updateCustomItem(item) {
      const { currentCustomItems } = this;

      this.currentCustomItems = currentCustomItems.map((currentItem) => {
        if (currentItem.id === item.id) return item;
        return currentItem;
      });
    },
    updateCheckedItems(item) {
      const { checkedItems } = this;
      if (checkedItems.some((checkedItem) => checkedItem.id === item.id)) {
        this.checkedItems = checkedItems.filter((checkedItem) => checkedItem.id !== item.id);
        return;
      }

      this.checkedItems.push(item);
    },
    deleteTemplate() {
      const { $store, novelId, $router, templateId } = this;
      const confirmDialog = new Dialog(SimpleDialog);
      const positiveCallback = async () => {
        this.isProgress = true;
        const { dispatch } = $store;
        await dispatch("materialModule/deleteMaterialInputTemplate", [templateId]);
        $router.push({ name: "templates", params: { novelId, templateId } });
        this.isProgress = false;
      };
      const data: SimpleDialogProps = {
        title: "資料テンプレートを削除しますか？",
        content: "本当に資料資料テンプレートを削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback,
      };
      confirmDialog.show(data);
    },

    // event handle (button, checkbox, etc)

    onReorder(items) {
      this.currentCustomItems = items;
    },
    onSelectLayout(event) {
      const { target } = event;
      if (target instanceof HTMLSelectElement) {
        const { setBasicItems } = this;
        this.currentLayout = target.value as LayoutEnum;
        setBasicItems(target.value);
      }
    },
    onAddTextClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.TEXT_MULTILINE);
    },
    onAddSquareClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_SQUARE);
    },
    onAddPortraitClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_PORTRATE);
    },
    onAddLandscapeClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_LANDSCAPE);
    },
    onSaveButtonClick() {
      const { isActiveSaveButton } = this;

      if (!isActiveSaveButton) {
        return;
      }

      this.isProgress = true;

      const { $store, templateId, createTemplate, updateTemplate } = this;
      const { getters } = $store;
      const template = getters["materialModule/template"](templateId);
      if (template) {
        updateTemplate();
        return;
      }

      createTemplate();
    },
    onDeleteButtonClick() {
      const { currentCustomItems, checkedItems, isActiveDeleteButton } = this;

      if (!isActiveDeleteButton) {
        return;
      }

      if (checkedItems.length > 0) {
        this.currentCustomItems = currentCustomItems.filter(
          (item) => !checkedItems.some((checkedItem) => checkedItem.id === item.id)
        );
        this.checkedItems = [];
        return;
      }

      const { deleteTemplate } = this;
      deleteTemplate();
    },
    onUpdateItem(item) {
      const { updateCustomItem } = this;
      updateCustomItem(item);
    },
    onUpdateCheckItem(item) {
      const { updateCheckedItems } = this;
      updateCheckedItems(item);
    },
    onCancelClick() {
      const { $router } = this;
      $router.back();
    },

    // util

    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
    showLeaveConfirmDialog(next) {
      const confirmDialog = new Dialog(SimpleDialog);
      const options: SimpleDialogProps = {
        title: "資料テンプレートを編集中です。",
        content: "保存していないデータが存在します。\nこのまま画面遷移しますか？\n※データは作成されません。",
        negative: "キャンセル",
        positive: "遷移する",
        positiveCallback: () => {
          next();
        },
      };

      confirmDialog.show(options);
    },
    beforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "編集中です。";
    },
    initializeOriginalData() {
      this.originalData = {
        name: this.name,
        currentLayout: this.currentLayout,
        currentBasicItems: [...this.currentBasicItems],
        currentCustomItems: [...this.currentCustomItems],
      };
    },
  },

  // route event

  beforeRouteLeave(to, from, next) {
    const { isProgress, isFormChanged, showLeaveConfirmDialog } = this;

    if (isProgress || !isFormChanged) {
      next();
      return;
    }

    showLeaveConfirmDialog(next);
  },
});
