var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "list-tile",
    {
      staticClass: "tile",
      style: {
        paddingRight: _vm.isBasic ? "24px" : "16px",
        paddingLeft: _vm.isBasic ? "24px" : "16px",
      },
      attrs: { height: "auto" },
    },
    [
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "leading",
              attrs: { slot: "leading" },
              slot: "leading",
            },
            [
              _vm.isCheck
                ? _c("checkbox-marked-outline-icon", {
                    staticClass: "mr-12 center pointer",
                    attrs: { size: 20 },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onCheck.apply(null, arguments)
                      },
                    },
                  })
                : _c("checkbox-blank-outline-icon", {
                    staticClass: "mr-12 center pointer",
                    attrs: { size: 20 },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onCheck.apply(null, arguments)
                      },
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.inputType.includes("text")
        ? _c(
            "div",
            {
              staticClass: "content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _vm.inputType.includes("singleline")
                ? _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.singlelineLabel) + "（必須）"),
                    ]),
                  ])
                : _vm._e(),
              _vm.inputType.includes("multiline")
                ? _c("div", [
                    _c("span", [_vm._v("テキストパーツ（30文字以内）")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "single",
                      attrs: { type: "text", maxlength: _vm.titleLimit },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.inputType.includes("image")
        ? _c(
            "div",
            {
              staticClass: "content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _vm.isBasic
                ? _c("div", [_c("span", [_vm._v("イメージ画像（必須）")])])
                : _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.imageLabel))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "single",
                      attrs: { type: "text", maxlength: _vm.titleLimit },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                  ]),
            ]
          )
        : _vm._e(),
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "trailing",
              attrs: { slot: "trailing" },
              slot: "trailing",
            },
            [
              _c("img", {
                staticClass: "drag-icon",
                attrs: { src: require("@/assets/img/icon/drag.png") },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }